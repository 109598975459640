<template>
  <div
    :class="{
      scrolling: changeColor,
      'mobile-menu': mobileMenu,
    }"
    class="menu"
  >
    <div class="logo">
      <router-link
        @click="mobileMenu ? (mobileMenu = false) : (mobileMenu = false)"
        to="/"
      >
        <img src="@/assets/images/logo.png" alt="Logo firmy B&M Domy" />
      </router-link>
    </div>
    <ul
      @click="mobileMenu ? (mobileMenu = false) : (mobileMenu = false)"
      class="navigation"
    >
      <li><router-link to="/">Domov</router-link></li>
      <li><router-link to="/o-nas">O nás</router-link></li>
      <li><router-link to="/galeria">Galéria</router-link></li>
    </ul>
    <!-- Hamburger ikona pre mobilné zariadenia -->
    <div class="hamburger" @click="mobileMenuToggle">
      <div class="hamburger-line"></div>
      <div class="hamburger-line"></div>
      <div class="hamburger-line"></div>
    </div>
    <div class="contact">
      <div
        @click="
          $router.push('/kontakt');
          mobileMenu ? (mobileMenu = false) : (mobileMenu = false);
        "
        class="button-border"
      >
        <div class="button">
          <p>Kontakt</p>
          <img src="@/assets/images/arrow.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuView",
  data() {
    return {
      changeColor: false,
      mobileMenu: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleScroll() {
      // Získanie aktuálnej pozície scrollu
      const scrollPosition = window.scrollY || window.pageYOffset;

      // Podmienka na zmenu farby pozadia podľa pozície scrollu
      if (scrollPosition > 200 || window.scrollY > 200) {
        this.changeColor = true; // Použitie tmavšej farby
      } else {
        this.changeColor = false; // Použitie predvolenej farby
      }
    },
    mobileMenuToggle() {
      this.mobileMenu = !this.mobileMenu;
    },
    handleResize() {
      this.mobileMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//import color variables
@import "@/assets/sass/_colors.scss";

.hamburger {
  display: none; /* Skryté na desktopových zariadeniach */
  cursor: pointer;
  padding: 0.5em;
  margin: auto;
  margin-right: 1em;

  .hamburger-line {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 5px 10% 5px auto;
    transition: transform 0.3s, opacity 0.3s;
  }
}

.menu {
  display: flex;
  padding: 1em 5%;
  box-sizing: border-box;
  background: transparent;

  position: fixed;
  width: 100vw;
  height: auto;
  top: 0;
  left: 0;
  transition-duration: 0.2s;
  z-index: 100;
}

.scrolling {
  background: hsla(0, 0%, 94%, 0.8);
  padding: 0.7em 5%;
}

.logo {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    width: 5em;
    height: auto;
  }
}

.navigation {
  width: 60%;
  max-width: 30em;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.1em;

  li {
    padding: 0.4em 1.1em;
    background: transparent;
    border-radius: 1.5em;
    text-decoration: none;

    &:hover {
      box-shadow: 3px 3px 5px #ececec;
    }

    a {
      text-decoration: none;
    }
  }

  li:has(.router-link-active.router-link-exact-active) {
    background: #fff;
    font-weight: 500;
  }
}

.mobile-menu .navigation li:has(.router-link-active.router-link-exact-active) {
  background: none;
  font-weight: 500;
}
.mobile-menu .navigation li:hover {
  box-shadow: none;
}

.mobile-menu .navigation li {
  width: 90%;
  border-radius: 0;
  line-height: 2em;
}
.mobile-menu .navigation li:not(:last-child) {
  border-bottom: 0.1em solid #c6c6c6;
}

.mobile-menu .navigation {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: none;
  top: 6em;
  background: #dedede;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  border: 0.6em solid #fbfbfb;
  outline: 0.1em solid #efefef;
  border-radius: 1.5em;
  box-sizing: border-box;
  background: #ffffffdb;
}

.contact {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .button {
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.7em;
    background: #fff;
    padding: 0.8em 1.2em;
    border-radius: 1.5em;

    p {
      font-weight: 500;
      font-size: 1.1em;
    }

    img {
      width: 1.3em;
    }
  }

  .button-border {
    border: 0.8em solid transparent;
    outline: 0.12em solid #fff;
    border-radius: 2em;

    &:hover {
      border-radius: 1.5;
      transition-duration: 0.3s;
      box-shadow: 3px 3px 5px #ececec;

      .button {
        gap: 1.3em;
        transition-duration: 0.3s;
        transform: scale(1.1);
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .contact {
    .button-border {
      border: 0.6em solid transparent;
      outline: 0.1em solid #fff;

      &:hover {
        border-radius: 1.5;
        transition-duration: 0.3s;
        box-shadow: 3px 3px 5px #ececec;

        .button {
          gap: 1.3em;
          transition-duration: 0.3s;
          transform: scale(1.1);
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .hamburger {
    display: block;
    width: 60%;
  }

  .navigation {
    display: none;
  }

  .logo,
  .contact {
    width: 35%;
  }

  .navigation {
    width: 30%;
  }
}
</style>
