<template>
  <footer>
    <div class="footer">
      <div class="column">
        <div class="logo">
          <img src="@/assets/images/logoCele.png" alt="Logo firmy B&M Domy" />
        </div>
        <div class="links">
          <ol>
            <li><router-link to="/">Domov</router-link></li>
            <li>/</li>
            <li><router-link to="/o-nas">O nás</router-link></li>
            <li>/</li>
            <li><router-link to="/galeria">Galéria</router-link></li>
            <li>/</li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=100039615015590"
                >Facebook</a
              >
            </li>
            <li>/</li>
            <li><router-link to="/kontakt">Kontakt</router-link></li>
          </ol>
        </div>
      </div>
      <div class="column">
        <div class="item">
          <p class="small">ZAVOLAJTE NÁM</p>
          <p class="text">+421 911 936 648</p>
        </div>
        <div class="item">
          <p class="small">EMAIL</p>
          <p class="text">info@bmdomy.eu</p>
        </div>
      </div>
      <div class="column">
        <div class="item">
          <p class="small">ADRESA</p>
          <p class="text">Gaceľovská 1238/20 029 44 Rabča</p>
        </div>
        <div class="button-border">
          <div class="button">
            <router-link to="/kontakt">napíšte nám</router-link>
          </div>
        </div>
      </div>
      <div class="column">
        <div @click="goTop()" class="item">
          <div class="arrow">
            <img src="@/assets/images/arrow.svg" alt="Posunutie na hor" />
          </div>
        </div>
        <p class="small">© 2024 — Copyrigh</p>
      </div>
    </div>

    <div class="small-footer">
      <p>
        Umiestňovanie obsahu na iné stránky, jeho rozširovanie tlačou a inými
        médiami podlieha autorským právam a je možné iba s písomným súhlasom
        firmy B&M Domy.
      </p>
      <p>
        Web by <a href="https://luboskukla.sk" target="_blank"> Ľubos Kukla</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterView",
  methods: {
    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//import color variables
@import "@/assets/sass/_colors.scss";

.footer {
  background: #18191b;
  display: flex;
  gap: 5%;
  align-items: stretch;
  justify-content: space-between;
  padding: 5em 2% 2em;
  width: 100%;
  min-height: 20em;
  box-sizing: border-box;

  .column {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    &:nth-child(4) {
      flex: 1;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .logo img {
    width: 6em;
  }

  p,
  a,
  li {
    color: #d4d5d9;
    text-align: left;
  }

  .small {
    font-weight: 200;
    font-size: 0.8em;
    margin-bottom: 0.6em;
    margin-top: 2.3em;
  }

  .text {
  }

  .links {
    margin-top: auto;
    max-width: 20em;
  }

  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;

    display: flex;
    column-gap: 1.2em;
    flex-wrap: wrap;
    align-items: center;
  }
}

.arrow {
  background: #d4d5d9;
  margin-top: -3em;
  padding: 0.7em 0.9em;
  border-radius: 100%;
  cursor: pointer;
  font-size: 90%;

  &:hover {
    transform: scale(1.2);
    transition-duration: 0.2s;
    padding-top: 1.5em;
  }

  img {
    filter: brightness(0);
    transform: rotate(-90deg);
    width: 1.1em;
  }
}

.button {
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.7em;
  background: #fff;
  padding: 0.6em 1.2em;
  border-radius: 1.5em;

  a {
    font-weight: 500;
    font-size: 1.1em;
    color: #030301;
    white-space: nowrap;

    text-decoration: none;
  }

  img {
    width: 1.3em;
  }
}

.button-border {
  border: 0.6em solid transparent;
  outline: 0.1em solid #fff;
  border-radius: 2em;
  margin-top: 2em;

  &:hover {
    border-radius: 1.8em;
    transition-duration: 0.3s;
    box-shadow: 3px 3px 5px #2d2d2d;

    .button {
      padding: 0.6em 1.3em;
      gap: 1.3em;
      transition-duration: 0.3s;
      transform: scale(1.1);
    }
  }
}

.small-footer {
  padding: 1em;
  background: #000;

  p {
    color: #fff;
    font-size: 0.9em;
    font-weight: 200;
  }

  a {
    color: #fff;
    font-weight: 500;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 750px) {
  .footer {
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    .column {
      margin: auto;
      min-width: 80%;

      &:first-child {
        align-items: center;
      }
    }

    ol {
      justify-content: center;
    }

    .links {
      max-width: none;
      margin-top: 2em;
    }

    .arrow {
      padding: 0.5em 0.9em;
    }

    .logo img {
      width: 8em;
    }
  }
}
</style>
