import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "domov",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/o-nas",
    name: "o nás",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/galeria",
    name: "galéria",
    component: () => import("../views/GalleryView.vue"),
  },
  {
    path: "/prispevok",
    name: "Príspevok",
    component: () => import("../views/PostView.vue"),
  },
  {
    path: "/galeria",
    name: "galéria",
    component: () => import("../views/GalleryView.vue"),
  },
  {
    path: "/kontakt",
    name: "kontakt",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/404View.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
